.nav-bar {
    background: #eaeaea;
    width: 100px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    min-height: 500px;

    .logo {
        display: block;
        padding: 0px 0;

        img {
            display: block;
            margin: 0px auto;
            width: 100px;
            height: auto;
        }
    }

    nav {
        display: block;
        text-align: center;
        position: absolute;
        height: 210px;
        top: 50%;
        width: 100%;
        margin-top: -120px;

        a {
            font-size: 22px;
            color: #4d4d4e;
            display: block;
            line-height: 51px;
            position: relative;
            text-decoration: none;

            i {
                transition: all 0.3s;
            }

            &:hover {
                color: #4d4d4e;

                svg {
                    opacity: 0;
                }

                &::after {
                    opacity: 1;
                }
            }

            &:after {
                content: '';
                font-size: 15px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0%;
                display: block;
                width: 100%;
                text-align: center;
                opacity: 0%;
                transition: all 0.3s;
            }

            &:first-child {
                &::after {
                    content: 'HOME';
                }
            }
        }

        a.about-link {
            &::after {
                content: 'ABOUT';
            }
        }

        a.contact-link {
            &::after {
                content: 'CONTACT';
            }
        }

        a.projects-link {
            &::after {
                content: 'SKILLS';
            }
        }

        a.active {
            svg {
                color: rgb(77, 130, 183);
            }
        }
    }

    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        display: block;
        padding: 0;
        list-style: none;
        text-align: center;
        margin: 0;

        li {
            a {
                padding: 7px 0;
                display: block;
                font-size: 15px;
                line-height: 17px;

                &:hover {
                    svg {
                        color: #2e2e7b;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .nav-bar {
        width: 100%;
        height: auto;
        min-height: auto;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        
        .logo {
            padding: 10px 0;

            img {
                width: 50px;
            }
        }

        nav {
            position: relative;
            top: auto;
            margin-top: 0;
            height: auto;
            display: flex;
            justify-content: center;
            width: auto;

            a {
                font-size: 18px;
                line-height: normal;
                padding: 10px 20px;
                
                &:after {
                    font-size: 12px;
                    letter-spacing: normal;
                    position: relative;
                    bottom: auto;
                    display: block;
                    width: auto;
                    opacity: 1;
                    transition: none;
                    margin-top: 5px;
                }
            }
        }

        ul {
            position: relative;
            bottom: auto;
            display: flex;
            justify-content: flex-end;
            width: auto;
            padding: 0;
            margin-top: 0;
            
            li {
                a {
                    font-size: 18px;
                    padding: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .nav-bar ul {
        display: none;
    }
    .nav-bar .logo {
        display: none;
    }
    nav a{

     &:first-child{
        margin-left: 24%;
    }
}
}

@media screen and (max-width: 390px) {
    .nav-bar ul {
        display: none;
    }
    .nav-bar .logo {
        display: none;
    }
    nav a{

     &:first-child{
        margin-left: 20%;
    }
}
}