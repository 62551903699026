@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
.contact-form {
    width: 100%;
    margin-top: 20px;
  
    ul {
      padding: 0;
      margin: 0;
      margin-top: 0px;
  
      li {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: 10px;
        opacity: 0;
        overflow: hidden;
        display: block;
        clear: both;
        position: relative;
        animation: fadeInUp 2s 2s;
        animation-fill-mode: forwards;
      }
  
      li.half {
        width: 49%;
        margin-left: 2%;
        float: left;
        clear: none;
  
        &:first-child {
          margin-left: 0;
        }
      }
    }
  
    input[type='text'],
    input[type='email'] {
      width: 100%;
      border: 0;
      background: #bebebe;
      height: 50px;
      font-size: 16px;
      color: #000000;
      padding: 0 20px;
      box-sizing: border-box;
    }
  
    textarea {
      width: 100%;
      border: 0;
      background: #bebebe;
      height: 50px;
      font-size: 16px;
      color: #000000;
      padding: 20px;
      box-sizing: border-box;
      min-height: 150px;
    }
  
    .flat-button {
      color:#8d8ca4;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 4x;
      font-family: sans-serif;
      text-decoration: none;
      padding: 5px 20px;
      border: 1px solid #242425;
      margin-top: 5px;
      float:right;
      animation: fadeIn 1s 2.5s backwards;
      white-space: nowrap;
      border-radius: 10px;
      &:hover{
          background: rgb(77, 130, 183);
          color: white;
          border-color: rgb(77, 130, 183);
      }
    }
  }
  @media screen and (max-height: 700px) {
    
    #contacth1{
      margin-top: 20%;
    }
  }