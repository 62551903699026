.page{
    width: 100%;
    height: 100%;
    position: absolute;
}
.top-tags{
    bottom: auto;
    top:35px;
}
@media screen and (max-width: 1000px) {
  .top-tags,
  .bottom-tags{
    display: none;
  }
}


.tags{
    color: #8d8ca4;
    opacity: .6;
    position: absolute;
    bottom:0;
    left:140px;
    font-size: 20px;
    font-family: 'La Belle Aurore';
}

.bottom-tags{
    margin-bottom: 20px;
}
.bottom-tag-html{
   margin-left: -20px;
}
.container1{
    width: 100%;
    will-change: contents;
    height: 90%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;

}

.about-page,
.contact-page{
    .text-zone{
        position: absolute;
        left:12%;
        top: 40%;
        transform: translateY(-50%);
        width: 35%;
        vertical-align: middle;
        display: table-cell;

        max-height: 90%;

        h1{
            font-size: 55px;
            font-family: 'Coolvetica';
            color: black;
            font-weight: 400;
            margin-top: 400;
            position: relative;
            margin-bottom: 100px;
            left:10px;
            opacity: .7;

            &::before{
                content:'<h1>';
                font-family: 'La Belle Aurore';
                color: #8d8ca4;
                font-size: 24px;
                position: absolute;
                margin-top: -30px;
                left: 15px;
                opacity: .6;
    
            }
            &::after{
                content:'</h1>';
                font-family: 'La Belle Aurore';
                color: #8d8ca4;
                font-size: 24px;
                position: absolute;
                margin-top: 90px;
                left: 15px;
                opacity: .6;
    
            }


        }

        p{
            font-size: 16px;
            width: 30%;
            font-family: 'sans-serif';
            min-width: fit-content;
            color: black;
            font-weight: 400;
            margin: 0;
            line-height: 1.5;
            opacity: .7;
            position: relative;
            left:10px;
            animation:1s pulse ;

            &:nth-of-type(1){
                animation-delay: 1.1s;
                margin-top: 100px;

                &:before{
                    content:'<p>';
                    font-family: 'La Belle Aurore';
                    color: #8d8ca4;
                    font-size: 24px;
                    position: absolute;
                    margin-top: -40px;
                    left: 15px;
                    opacity: .6;
                }
            }

            &:nth-of-type(2){
                animation-delay: 1.3s;
            }
            &:nth-of-type(3){
                animation-delay: 1.5s;

                &:after{
                    content:'</p>';
                    font-family: 'La Belle Aurore';
                    color: #8d8ca4;
                    font-size: 24px;
                    position: absolute;
                    margin-top: 40px;
                    left: 15px;
                    opacity: .6;
                }
            }
        }
        // #contactP{
        //     font-size: 16px;
        //     width: 30%;
        //     font-family: 'sans-serif';
        //     min-width: fit-content;
        //     color: black;
        //     font-weight: 400;
        //     margin: 0;
        //     line-height: 1.5;
        //     opacity: .7;
        //     position: relative;
        //     left:10px;
        //     animation:1s pulse ;
        //     &:before{
        //        margin-top: 1px;
        //     }
            
        // }
        h4{
                font-size: 18px;
                width: 30%;
                font-family: 'sans-serif';
                min-width: fit-content;
                color: black;
                font-weight: 400;
                margin: 0;
                line-height: 1.5;
                opacity: .7;
                position: relative;
                left:10px;
                animation: 1s forwards ;
    
                &:nth-of-type(1){
                    animation-delay: 1.1s;
                    margin-top: 10px;}
    
        }

    }

}


@media screen and (max-width: 1200px) {
    .page {
      position: initial;
    }
  
    .container {
      position: initial;
      height: auto;
      min-height: auto;
  
      &.contact-page,
      &.about-page,
      &.portfolio-page {
        .text-zone {
          position: initial;
          transform: none;
          width: 100%;
          display: block;
          padding: 20px;
          box-sizing: border-box;
        }
      }
  
      &.portfolio-page {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
  
        h1.page-title {
          margin-left: 20px;
          margin-top: 20px;
        }
  
        .image-box {
          height: 200px;
          max-width: calc(50% - 10px);
        }
      }
    }
  
    .stage-cube-cont {
      position: initial;
      width: 100%;
      height: 0%;
      overflow: visible;
    }
  }


    
  @media print {
	* {
	  background: 0 0 !important;
	  color: #000 !important;
	  box-shadow: none !important;
	  text-shadow: none !important
	}
	a,
	a:visited {
	  text-decoration: underline
	}
	a[href]:after {
	  content: " (" attr(href) ")"
	}
	abbr[title]:after {
	  content: " (" attr(title) ")"
	}
	.ir a:after,
	a[href^="javascript:"]:after,
	a[href^="#"]:after {
	  content: ""
	}
	pre,
	blockquote {
	  border: 1px solid #999;
	  page-break-inside: avoid
	}
	thead {
	  display: table-header-group
	}
	tr,
	img {
	  page-break-inside: avoid
	}
	img {
	  max-width: 100% !important
	}
	@page {
	  margin: .5cm
	}
	p,
	h2,
	h3 {
	  orphans: 3;
	  widows: 3
	}
	h2,
	h3 {
	  page-break-after: avoid
	}
  }
    
  @media screen and (max-width: 900px) {
    .about-page,
    .contact-page{
        .text-zone{
            width: 90%;
            left:5%;
            h1{
              font-size: 38px;
          }
  

        }
    }

    
  }

  @media screen and (max-height: 700px) {
    .about-page,
    .contact-page{
        .text-zone{
            width: 90%;
            h1{
              font-size: 38px;
              margin-top: 30%;
          }
  

        }
    }

    
  }




