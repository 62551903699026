.img-dor{
    border-radius: 50%;
    width: 700px;
    animation: 1s fadeIn 4s backwards;
    
}

.img-design{
    position: relative;
    width: 500px;
    animation: 1s fadeIn 3s backwards;
    left: 25%;
}


@media (max-width: 1000px) {
    .img-dor,
    .img-design {
        display: none;
    }
}
