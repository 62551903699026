.home-page{
    h2{
        img{
            width: 100px;

        }

    }

    .image-zone{
        position: absolute;
        left: 50%;
        border-radius: 100px;
        top:20%;
    }



    .text-zone{
        position: absolute;
        left:12%;
        top: 50%;
        transform: translateY(-50%);
        // width: 40%;
        max-height: 90%;
    }
    h1{
        font-size: 55px;
        opacity: .7;
        color:rgb(0, 0, 0);
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;

        &::before{
            content:'<h1>';
            font-family: 'La Belle Aurore';
            color: #8d8ca4;
            font-size: 24px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: .6;

        }
        &::after{
            content:'</h1>';
            font-family: 'La Belle Aurore';
            color: #8d8ca4;
            font-size: 24px;
            position: absolute;
            margin-top: 230px;
            left: 15px;
            opacity: .6;

        }

    }
    h2{
        // color: black;
        margin-top: 30px;
        font-weight: 400;
        font-size: 18px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 2.4s backwards;

    }
    .flat-button{
        color:#8d8ca4;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 4x;
        font-family: sans-serif;
        text-decoration: none;
        padding: 5px 20px;
        border: 1px solid #242425;
        margin-top: 15px;
        float:left;
        animation: fadeIn 1s 2.5s backwards;
        white-space: nowrap;
        border-radius: 10px;
        &:hover{
            background: rgb(77, 130, 183);
            color: white;
            border-color: rgb(77, 130, 183);
        }
    }
    }
   

    @media screen and (max-width: 1000px) {
    
            .text-zone{
                width: 90%;
                h1{
                    font-size: 38px;
                }
    
            }
        
    
        
      }