.stage-cube-cont{
    width: 50%;
    height: 100%;
    top:0;
    padding-top: 18%;
    margin-left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;
    animation: 1s fadeIn 2.5s backwards;

}
@media screen and (max-width: 900px) {
  .stage-cube-cont{
    width: 100%;
    top:90%;
    height: 50%;
    padding-bottom: 45%;

  }
}

@media screen and (max-width: 600px) {
  .stage-cube-cont{
    width: 100%;
    top:105%;
    height: 50%;
    padding-bottom: 75%;

  }
  @media screen and (max-height: 800px) {
    .stage-cube-cont{
      width: 100%;
      top:150%;
      height: 50%;
      padding-bottom: 110%;
  
    }
  }
  @media screen and (max-height: 700px) {
    .stage-cube-cont{
      width: 100%;
      top:170%;
      height: 50%;
      padding-bottom: 120%;
  
    }
  }
}





.cubespinner{
    animation-name:spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);
    margin-bottom: 100%;


    div{
        position: absolute;
        width: 200px;
        height: 200px;
        border: 1px solid #ccc;
        background-color: rgba(255,255,255,0.5);
        justify-content: center;
        font-size: 100px;
        display: flex;
        align-items: center;
        box-shadow: 0 0 15px rgba(43, 40, 40, 0.5);
    
    }

    .face1{
        transform: translateZ(100px);
        color: rgb(11, 117, 255);
    }
    .face2{
        transform: rotateY(90deg) translateZ(100px);
        color: rgb(255, 128, 0);
    }
    .face3{
        transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
        color:rgb(200, 3, 3);
    }
    .face4{
        transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
        color:rgb(89, 184, 252);
    }
    .face5{
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
        color:rgb(247, 247, 16);
    }
    .face6{
        transform: rotateX(-90deg) translateZ(100px);
        color:rgb(100, 140, 170);
    }

}
@keyframes spincube {
    from,
    to {
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    16% {
      transform: rotateY(-90deg) rotateZ(90deg);
    }
    33% {
      transform: rotateY(-90deg) ;
    }
    50% {
      transform: rotateY(-180deg) rotateZ(90deg);
    }
    66% {
      transform: rotateY(-270deg) rotateX(90deg);
    }
    83% {
      transform: rotateX(90deg);
    }

}


@media screen and (max-height: 950px) {
  .about-page,
  .contact-page{
      .text-zone{
          h1{
            margin-top: 20%;
        }
      }
  }
}

@media screen and (max-height: 700px) {
  .about-page,
  .contact-page{
      .text-zone{
          h1{
            margin-top: 30%;
        }
      }
  }
}