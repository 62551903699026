@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
html{
  font-size: 62.5%;

}

body {
  margin: 0;
 font: 300 11px/1.4 'Helvetica Neue', sans-serif;
 color:#444;
  background-color:white;
  overflow: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 1200px) {
  body {
    overflow: visible;
  }
}


//   @media (min-width:1100px) and (min-height:900px) {
// 	h1 {
// 	  font-size: 73px !important;
// 	  line-height: 67px !important
// 	}
// 	.home-page h2 {
// 	  font-size: 14px !important
// 	}
// 	.text-zone p {
// 	  font-size: 16px !important;
// 	  line-height: 23px !important
// 	}
// 	.container.contact .text-zone h1:before,
// 	.container.skills .text-zone h1:before,
// 	.container.about .text-zone h1:before {
// 	  margin-top: -38px
// 	}
// 	.container.contact .text-zone h1:after,
// 	.container.skills .text-zone h1:after,
// 	.container.about .text-zone h1:after {
// 	  bottom: -44px
// 	}
// 	.bg {
// 	  -webkit-transform: scale(1.2);
// 	  -ms-transform: scale(1.2);
// 	  transform: scale(1.2)
// 	}
// 	.home-page h1 img {
// 	  width: 38px;
// 	}
//   }
  
//   @media (min-width:960px) and (max-height:800px) {
// 	.container.about .text-zone img {
// 	  display: none
// 	}
//   }
  
//   @media (max-width:1024px) {
// 	.container.contact .text-zone {
// 	  width: 36%
// 	}

// 	h1 {
// 	  font-size: 54px !important;
// 	  line-height: 45px
// 	}
// 	.container.about .text-zone {
// 	  width: 40%
// 	}
// 	.text-page .text-zone h1:after,
// 	.container.about .text-zone h1:after,
// 	.container.contact .text-zone h1:after {
// 	  bottom: -40px
// 	}
// 	.text-page .text-zone h1:before,
// 	.container.about .text-zone h1:before,
// 	.container.contact .text-zone h1:before {
// 	  margin-top: -29px
// 	}
// 	.home-page .text-zone,
// 	.text-page .text-zone {
// 	  width: 80%;
// 	  min-height: 0
// 	}
// 	.text-page .right-col {
// 	  margin-top: 10px;
// 	  position: static !important;
// 	  max-height: auto;
// 	  float: left;
// 	  margin-left: 10%;
// 	  -webkit-transform: none !important;
// 	  -ms-transform: none !important;
// 	  transform: none !important
// 	}
// 	.bg {
// 	  width: 263px;
// 	  height: 400px
// 	}
//   }
  
//   @media (max-width: 960px) {
// 	.home-page h1:before {
// 		left: 13px;
// 	}
// 	.home-page .flat-button {
// 		display: none;
// 	}
// 	#world {
// 	  position: relative !important;
// 	  width: 90% !important;
// 	  height: auto !important;
// 	  margin: auto
// 	}
// 	#page {
// 	  height: auto;
// 	  position: relative;
// 	}




	



	

// 	.container {
// 	  width: 100%;
// 	  height: auto;
// 	  padding-bottom: 58px;
// 	  box-sizing: border-box;
// 	  position: relative;
// 	  top: 0;
// 	  overflow: hidden
// 	}
// 	.bg {
// 	  right: 0;
// 	  left: 0;
// 	  opacity: .5
// 	}
// 	.container.gallery {
// 	  position: absolute;
// 	  min-height: 1200px
// 	}
// 	.magicwall .magicwall-grid {
// 	  margin-left: 0;
// 	  margin-top: 60px
// 	}
// 	.tags {
// 	  margin-left: 10px;
// 	  left: 0
// 	}
// 	.top-tags {
// 	  bottom: auto;
// 	  top: 76px
// 	}
// 	.bottom-tags {
// 	  position: absolute;
// 	  clear: both;
// 	  float: left;
// 	  margin-top: 22px;
// 	  bottom: 0
// 	}
// 	.text-zone {
// 	  position: static !important;
// 	  max-height: auto;
// 	  min-height: 350px;
// 	  float: left;
// 	  margin-left: 10%;
// 	  -webkit-transform: none !important;
// 	  -ms-transform: none !important;
// 	  transform: none !important;
// 	  margin-top: 170px
// 	}
// 	.container.about .text-zone,
// 	.container.skills .text-zone {
// 	  width: 80%;
// 	  min-height: 0
// 	}
// 	body {
// 	  overflow: auto
// 	}
// 	.blog-link {
// 	  display: none
// 	}
// 	.container.contact .text-zone {
// 	  width: 80%
// 	}
// 	.map-wrap {
// 	  float: none;
// 	  width: 80%;
// 	  margin-left: 10%;
// 	  height: 320px;
// 	  clear: both
// 	}
// 	.inf-map {
// 	  top: auto;
// 	  right: 5%;
// 	  bottom: 50px
// 	}

// 	.stage-cube-cont {
// 	  position: relative;
// 	  width: 100%;
// 	  height: 445px;
// 	}
//   }
  
//   @media (max-width:750px) {
// 	h1 {
// 	  font-size: 47px !important;
// 	  line-height: 40px !important
// 	}

//   }
  





  
