@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';



.image-zone{
    position: absolute;
    left: 50%;
    border-radius: 100px;
    top:20%;
}


.slider-zone{
  position: absolute;
  left: 50%;
  width: 45%;
  top: 8%;
  animation: 1s fadeIn 2s backwards;


}

@media (max-width: 1000px) {
  .slider-zone{ 
    top:88%;
    width: 100%;
    left: 1%;
     
  }
}

@media (max-width: 650px) {
  .slider-zone{ 
    top:100%;
     
  }
}
@media (max-width: 420px) {
  .slider-zone{ 
    top:105%;
     
  }
}





@media (max-width: 1000px) {
  .image-zone,
  .img-design {
      display: none;
  }
}



